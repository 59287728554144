import { Box, Button, CircularProgress, Checkbox, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography, IconButton, MenuItem, Backdrop, useTheme, useMediaQuery } from "@mui/material";
import React, {useState} from "react";
import moment from "moment";
import { Add, Clear } from "@mui/icons-material";
import { API, nationalities } from "../asset/conf";
import processDocument from "./data-extract";

export default function ClientForm(props) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const categories = [{label:'adulte', ages:[13]}, {label:'enfant b', ages:[5,12]}, {label:'enfant a', ages:[1,4]}, {label:'nourrisson', ages:[0,0]}]
    const [loading, setLoad] = useState(false)
    const [nom, setnom] = useState(props.client?.nom??'')
    const [prenom, setprenom] = useState(props.client?.prenom??'')
    const [birth_date, setbdate] = useState(props.client?.birth_date?moment.utc(props.client.birth_date).format('yyyy-MM-DD'):'')
    const [birth_place, setbplace] = useState(props.client?.birth_place??'')
    const [sex, setsex] = useState(props.client?.sex??'H')
    const [nationality, setnationality] = useState(props.client?.nationality??'')
    const [mail, setmail] = useState(props.client?.mail??'')
    const [tel, settel] = useState(props.client?.tel??'')
    const [observation, setobservation] = useState(props.client?.observation??'')
    const [isPmr, setpmr] = useState(props.client? props.client.isPmr : false)
    const [start, setStart] = useState(props.client?.identity_proof?.ddate? moment.utc(props.client.identity_proof?.ddate).format('yyyy-MM-DD'):'')
    const [end, setEnd] = useState(props.client?.identity_proof?.exp_date? moment.utc(props.client.identity_proof?.exp_date).format('yyyy-MM-DD'):'')
    const [id_prooftype, setid_prooftype] = useState(props.client?.identity_proof?.type??'cni')
    const [id_proofnum, setid_proofnum] = useState(props.client?.identity_proof?.num??'')
    const [proof_files, setproofFiles] = useState([''])
    const [sup_files, setsupFiles] = useState([''])
    const [tdsNum,setTdsNum] =useState(props.client?.tds?.num??'')
    const [tdsStart,setTdsStart] = useState(props.client?.tds?.start?moment.utc(props.client.tds.start).format('yyyy-MM-DD'):'')
    const [tdsEnd,setTdsEnd] = useState(props.client?.tds?.end?moment.utc(props.client.tds.end).format('yyyy-MM-DD'):'') 

    const [extract_lang, setLang] = useState('fra')
    const [extract_file, setExtractfile] = useState('')
    const [proofInvalid, setProofValidity] = useState(false) 
    
    function getProofValidity(){
        if (props.proofValidity&&moment.utc(end).isBefore(moment.utc(props.proofValidity).add(6,'M'))) {
            alert('La pièce d\'identité doit être valide jusqu\'à minimum 6 mois après la date de départ')
            props.setProofInvalid(true)
            return true
        } else  {
            props.setProofInvalid(false)
            return false
        }
    }

    function handleImport_extractFile(event, index, isproof) {
        const file = event.target.files[0]
        if(file) setExtractfile(file)          
    }

    async function handleExtract() {
        console.log('handle extract called');
        setLoad(true)
        const extractedInfo = await processDocument(extract_file, extract_lang)
        console.log('extracted data: ',extractedInfo);
        setLoad(false)
    }

    function handleSubmit(event) {
        event.preventDefault()
        setLoad(true)
        const age = ()=> {
            var aujourdHui = new Date();
            var dateNaissance = new Date(birth_date);
            var age = aujourdHui.getFullYear() - dateNaissance.getFullYear();
            var m = aujourdHui.getMonth() - dateNaissance.getMonth();
            if (m < 0 || (m === 0 && aujourdHui.getDate() < dateNaissance.getDate())) {
                age--;
            }
            return age<0 ? 0 : age;
        }
        if (age()<18) {
            props.setmineur(true)
        }
        let categorie = categories.find(cat=> cat.ages.length>1 ? (age()>= cat.ages[0]&&age()<=cat.ages[1]) : age()>=cat.ages[0])?.label?? ''
        const client = {
            nom: nom,
            prenom: prenom,
            birth_date: new Date(birth_date),
            birth_place: birth_place,
            sex: sex,
            nationality: nationality,
            categorie: categorie,
            identity_proof:JSON.stringify({
                type : id_prooftype,
                num : id_proofnum,
                ddate: new Date(start),
                exp_date: new Date(end),
            }),
            tds_proof:JSON.stringify({num: tdsNum, ddate: tdsStart, exp_date: tdsEnd}),
            mail: mail,
            tel: tel,
            observation: observation,
            isPmr: isPmr,
            isWeb: true
        }
        let formdata = new FormData()
        for (let key in client) {
            formdata.append(key, client[key]);
        }
        const proof = proof_files.filter(f=> f!=='')
        for (let index = 0; index < proof.length; index++) {
            formdata.append('prooffile', proof[index])
        }

        const supfiles = sup_files.filter(f=> f!=='')
        for (let index = 0; index < supfiles.length; index++) {
            formdata.append('supfile', supfiles[index])
        }

        fetch(API.concat('/passagers'), {method:'POST', body: formdata})
        .then(async(response)=>{
            if (response.status === 200) {
                const json = await response.json()
                props.add(json)
                props.close()
            }
            setLoad(false)
        })
    }

    function handleImport(event, index, isproof) {
        const file = event.target.files[0]
        if (isproof) {
            if (!proof_files.some(f => f.name===file.name && f.size===file.size && f.lastModified===file.lastModified)) {
                setproofFiles(old => {let newFiles=[...old]; newFiles[index]=event.target.files[0]; return newFiles})            
            }
        } else {
            if (!sup_files.some(f => f.name===file.name && f.size===file.size && f.lastModified===file.lastModified)) {
                setsupFiles(old => {let newFiles=[...old]; newFiles[index]=event.target.files[0]; return newFiles})            
            }
        }
        
    }

    return(isMobile?
      <Box 
      component='form' 
      onSubmit={handleSubmit} 
      sx={{ 
        padding: '15px', 
        '& .MuiTextField-root': { marginBottom: 2 } 
      }}
      >
        <Stack spacing={2}>
          {/* Sex Selection */}
          <RadioGroup 
            row 
            value={sex} 
            onChange={(e) => setsex(e.target.value)} 
            sx={{ 
              justifyContent: 'center', 
              width: '100%' 
            }}
          >
            <FormControlLabel value={'H'} control={<Radio />} label="Mr" />
            <FormControlLabel value={'F'} control={<Radio />} label="Mme" />
          </RadioGroup>

          {/* Name Fields */}
          <TextField
            fullWidth
            label="Nom"
            value={nom}
            onChange={(e) => setnom(e.target.value)}
            required
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Prénom"
            value={prenom}
            onChange={(e) => setprenom(e.target.value)}
            required
            variant="outlined"
          />

          {/* Birth Details */}
          <TextField
            fullWidth
            label="Date de naissance"
            type="date"
            value={birth_date}
            onChange={(e) => setbdate(e.target.value)}
            required
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Lieu de naissance"
            value={birth_place}
            onChange={(e) => setbplace(e.target.value)}
            required
            variant="outlined"
          />

          {/* Nationality */}
          <TextField
            select
            fullWidth
            label="Nationalité"
            value={nationality}
            onChange={(e) => {
              setnationality(e.target.value);
              if (e.target.value !== "Français") setid_prooftype('passport');
            }}
            required
            variant="outlined"
          >
            {nationalities.map((item) => (
              <MenuItem key={item.label} value={item.label}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>

          {/* Contact Details */}
          <TextField
            fullWidth
            label="Adresse mail"
            type="email"
            value={mail}
            onChange={(e) => setmail(e.target.value)}
            required
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Tél"
            type="tel"
            value={tel}
            onChange={(e) => settel(e.target.value)}
            variant="outlined"
          />

          {/* ID Proof */}
          <RadioGroup
            row
            value={id_prooftype}
            onChange={(e) => setid_prooftype(e.target.value)}
            sx={{ justifyContent: 'center', width: '100%' }}
          >
            <FormControlLabel 
              value={'cni'} 
              disabled={!nationalities.find(n => n.label === nationality)?.pieces.includes('cni')} 
              control={<Radio />} 
              label="CNI" 
            />
            <FormControlLabel value={'passport'} control={<Radio />} label="Passport" />
          </RadioGroup>

          <TextField
            fullWidth
            label="N° de la pièce d'identité"
            value={id_proofnum}
            onChange={(e) => setid_proofnum(e.target.value)}
            required
            variant="outlined"
          />

          {/* ID Dates */}
          <TextField
            fullWidth
            label="Date de délivrance"
            type="date"
            value={start}
            onChange={(e) => setStart(e.target.value)}
            required
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Date d'expiration"
            type="date" color={proofInvalid?'red':'primary'} error={proofInvalid}
            value={end} onBlur={()=>setProofValidity(getProofValidity())}
            onChange={(e) => setEnd(e.target.value)}
            required
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />

          {/* File Uploads */}
          <Typography variant="subtitle1">
            Téléverser votre pièce d'identité / passeport (optionnel):
          </Typography>
          {proof_files.map((file, index) => (
            <Stack key={index} direction="row" spacing={1} alignItems="center">
              <input 
                type="file" 
                name="proof_file" 
                accept=".pdf, image/*" 
                onChange={(event) => handleImport(event, index, true)} 
              />
              <IconButton onClick={() => setproofFiles(old => {
                let newf = [...old];
                newf.splice(index, 1);
                return newf;
              })}
              >
                <Clear />
              </IconButton>
            </Stack>
          ))}
          {proof_files[proof_files.length - 1] !== '' && proof_files.length < 2 && (
            <Button 
              variant="outlined" 
              startIcon={<Add />} 
              onClick={() => setproofFiles(old => {
                let newf = [...old];
                newf.push('');
                return newf;
              })}
            >
              Ajouter un document
            </Button>
          )}

          {/* Observation */}
          <TextField
            label="Observation"
            multiline
            fullWidth
            rows={3}
            placeholder="Indiquer si vous avez des problèmes cardiaques ou d'asthme, des allergies ou tout autre problème"
            value={observation}
            onChange={(e) => setobservation(e.target.value)}
            variant="outlined"
          />

          {/* PMR Checkbox */}
          <FormControlLabel
            control={
              <Checkbox
                checked={isPmr}
                onChange={() => {
                  setpmr(old => !old);
                  setLoad(false);
                }}
                color="primary"
              />
            }
            label="Je suis une personne à mobilité réduite"
          />

          {/* Submit Button */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading||proofInvalid}
            fullWidth
            sx={{ mt: 2 }}
          >
            {loading && <CircularProgress size={18} color="inherit" style={{ marginRight: 7 }} />}
            Enregistrer passager {props.index + 1}
          </Button>
        </Stack>

      <Backdrop open={loading}><CircularProgress /></Backdrop>
    </Box>
        :<Box component='form' onSubmit={handleSubmit} sx={{padding:'20px'}}>
            <Stack direction='column' spacing={2}>
                <Stack direction='row' alignItems='center' spacing={2}>
                    <RadioGroup row value={sex} onChange={(e)=> setsex(e.target.value)} sx={{minWidth:'160px'}}>
                        <FormControlLabel value={'H'} control={<Radio />} label="Mr" />
                        <FormControlLabel value={'F'} control={<Radio />} label="Mme" />
                    </RadioGroup>
                    <TextField
                    fullWidth sx={{minWidth:'200px'}} margin="normal" color='primary'
                    value={nom}
                    onChange={(e)=> setnom(e.target.value)}
                    required label="Nom" type="text" autoFocus/>
                    <TextField
                    fullWidth sx={{minWidth:'200px'}} margin="normal" color='primary'
                    value={prenom}
                    onChange={(e)=> setprenom(e.target.value)}
                    required label="prénom" type="text"/>
                </Stack>
                <Stack direction='row' alignItems='center' spacing={2}>
                    <TextField
                    margin="normal" color='primary' sx={{minWidth:'160px'}}
                    required label="Date de naissance" type="date"
                    value={birth_date}  InputLabelProps={{shrink: true}}
                    onChange={(e)=>setbdate(e.target.value)}/>
                    <TextField
                    fullWidth margin="normal" color='primary' sx={{minWidth:'200px'}} 
                    required label="Lieu de naissance" type="text"
                    value={birth_place}
                    onChange={(e)=>setbplace(e.target.value)}/>
                    <TextField select={nationality===""||nationalities.map(n=> n.label).includes(nationality)}
                    fullWidth margin="normal" color='primary' sx={{minWidth:'200px'}} required label="Nationalité"
                    value={nationality}
                    onChange={(e)=>{setnationality(e.target.value); if(e.target.value!=="Français")setid_prooftype('passport')}}>
                        {nationalities.map(item=><MenuItem key={item.label} value={item.label}>{item.label}</MenuItem>)}
                    </TextField>
                </Stack>
                <Stack direction='row' alignItems='center' spacing={2}>
                    <TextField
                    fullWidth required sx={{minWidth:'250px'}} label="Adresse mail" type="email"
                    value={mail} onChange={(e)=>setmail(e.target.value)}/>
                    <TextField
                    fullWidth sx={{minWidth:'200px'}} label="Tél" type="tel"
                    value={tel} onChange={(e)=>settel(e.target.value)}/>
                </Stack>
                <Stack direction='row' alignItems='center' spacing={2}>
                    <RadioGroup row value={id_prooftype} onChange={(e)=> setid_prooftype(e.target.value)} sx={{minWidth:'200px', width:'40%'}}>
                        <FormControlLabel value={'cni'} disabled={!nationalities.find(n=>n.label===nationality)?.pieces.includes('cni')} control={<Radio />} label="CNI" />
                        <FormControlLabel value={'passport'} control={<Radio />} label="Passport" />
                    </RadioGroup>
                    <TextField
                    fullWidth sx={{minWidth:'200px'}} margin="normal" color='primary'
                    value={id_proofnum}
                    onChange={(e)=> setid_proofnum(e.target.value)}
                    required label="N° de la pièce d'identité" type="text"/>
                </Stack>
                <Stack direction='row' alignItems='center' spacing={2}>
                    <TextField
                    margin="normal" color='primary' sx={{minWidth:'150px', width:'25%'}}
                    required label="Date de délivrance" type="date"
                    value={start}  InputLabelProps={{shrink: true}}
                    onChange={(e)=>setStart(e.target.value)}/>
                    <TextField
                    margin="normal" color={proofInvalid?'red':'primary'} error={proofInvalid} sx={{minWidth:'150px', width:'25%'}}
                    required label="Date d'expiration" type="date" 
                    value={end}  InputLabelProps={{shrink: true}} onBlur={()=>setProofValidity(getProofValidity())}
                    onChange={(e)=>setEnd(e.target.value)}/>
                </Stack>
                {nationality==="Sainte Lucie - avec Titre de séjour" &&<Box>
                    <Typography variant="subtitle1" marginBottom={1}>Titre de séjour</Typography>
                    <Stack direction='row' alignItems='center' spacing={2}>
                        <TextField fullWidth sx={{minWidth:'200px'}} margin="normal" label="N° du Titre de Séjour"
                        value={tdsNum} onChange={(e)=> setTdsNum(e.target.value)}/>
                        <TextField
                        sx={{minWidth:'150px', width:'25%'}} label="Date de délivrance" type="date" InputLabelProps={{shrink: true}}
                        value={tdsStart} onChange={(e)=>setTdsStart(e.target.value)}/>
                        <TextField
                        sx={{minWidth:'150px', width:'25%'}} label="Date d'expiration" type="date" InputLabelProps={{shrink: true}}
                        value={tdsEnd} onChange={(e)=>setTdsEnd(e.target.value)}/>
                    </Stack>
                </Box>}
                <Typography variant="subtitle1">Téléverser votre pièce d'identité / passeport (optionnel):</Typography>
                <Box sx={{display:'flex', flex:1, flexDirection:'column'}}>{proof_files.map((file, index)=>(
                    <Stack key={index} direction={'row'} spacing={2} alignItems={'center'}>
                        <input type='file' name='proof_file' accept='.pdf, image/*' onChange={(event)=>{handleImport(event, index, true)}} />
                        <IconButton onClick={()=>setproofFiles(old=>{let newf=[...old]; newf.splice(index, 1); return newf})}><Clear /></IconButton>
                    </Stack>
                    ))}
                    {proof_files[proof_files.length-1]!=='' && proof_files.length<2 &&
                    <IconButton sx={{backgroundColor:'primary.main', alignSelf:'center'}} onClick={()=>setproofFiles(old=>{let newf=[...old]; newf.push(''); return newf})}>
                        <Add color="secondary" />
                    </IconButton>}
                </Box>
               {nationality.includes('Sainte Lucie') && <Box sx={{display:'flex', flex:1, flexDirection:'column'}}>
                    <Typography variant="subtitle1">Importer des fichiers supplémentaires(max 5)</Typography>
                    {nationalities.find(n=>n.label===nationality)?.pieces.filter(p=>!['passeport', 'cni'].includes(p)).length>0&&
                    <Typography color='red.main' fontWeight={500}>Vous devez Importer les documents suivants: <span style={{color:'#3F88C5'}}>{nationalities.find(n=>n.label===nationality).pieces.filter(p=>!['passeport', 'cni'].includes(p)).join(', ')}</span></Typography>}
                    <Stack direction={'row'} spacing={2} alignItems={'center'} flexWrap='wrap' marginTop={2}>
                        {sup_files.map((file, index)=>(
                        <Stack key={index} direction={'row'} spacing={2} alignItems={'center'}>
                            <input type='file' name='proof_file' accept='.pdf, image/*' onChange={(event)=>{handleImport(event, index, false)}} />
                            <IconButton onClick={()=>setsupFiles(old=>{let newf=[...old]; newf.splice(index, 1); return newf})}><Clear /></IconButton>
                        </Stack>
                        ))}
                    </Stack>
                    {sup_files[sup_files.length-1]!=='' && sup_files.length<5 &&
                    <Button  onClick={()=>setsupFiles(old=>{let newf=[...old]; newf.push(''); return newf})}>
                        Ajouter un document
                    </Button>}
                </Box>}
                <TextField
                label='Observation' multiline placeholder="Indiquer si vous avez des problèmes cardiaques ou d'asthme, des allergies ou tout autre problème"
                sx={{width:'50%', minWidth:'350px'}}
                rows={3} value={observation}
                onChange={(e)=>setobservation(e.target.value)}/>
                <FormControlLabel sx={{}} control={<Checkbox checked={isPmr} onChange={()=> {setpmr(old=>!old); setLoad(false)}} color="primary" />} label="Je suis une personne à mobilité réduite" />
                <Button
                type="submit"
                variant="contained"
                style={{alignSelf:'center'}}
                color="primary"
                disabled={loading||proofInvalid}
                >
                {loading && <CircularProgress size={18} color='primary' style={{marginRight:7}}/> } Enregistrer passager {props.index+1}
                </Button>
            </Stack>
            <Backdrop open={loading}><CircularProgress/></Backdrop>
        </Box>
    )
}