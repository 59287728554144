import React, { useContext, useEffect, useState } from "react";
import { Context } from "../App";
import { Backdrop, Box, Button, CircularProgress, Divider, Paper, Stack, Typography } from "@mui/material";
import moment from 'moment';
import 'moment/locale/fr';
import { API } from "../asset/conf";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, useTheme } from '@mui/material';


export default function Recap() {
    moment.locale('fr')
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate()
    const {isAR, isShopping, rotations, passagers, mail, tel, contact, address1, address2, zipcode, city, country, setReservation} = useContext(Context)
    const [load, setLoad] = useState(false)
    const [price, setPrice] = useState(0)

    async function handleSubmit() {
        setLoad(true)
        
        const rsv = {
            isA_R: isAR,
            date_depart: new Date(rotations[0].date_depart),
            rotations: rotations.map(rtn=> rtn._id),
            passagers: passagers.map(psg=>psg._id),
            total: price,
            rest_to_pay: price,
            pay_status: 'en attente',
            mail:mail,
            tel:tel,
            contact:contact,
            address1:address1,
            address2:address2,
            zipcode:zipcode,
            city:city,
            country:country,
            where: 'Web'
        }
        if(isAR) rsv.date_retour = new Date(rotations[1].date_depart)
        fetch(API.concat('/reservation/register'), {method:'POST', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }, body: JSON.stringify(rsv)
        }).then(async reponse=>{
            const rsv = await reponse.json()
            setReservation(rsv)
            setLoad(false)
            navigate('/confirmation')
        })
    }

    useEffect(()=>{
        function getTarifs() {
            const tfId = [...new Set(rotations.map(rt=>rt.tarifs??[]).flat())]
            fetch(API.concat(tfId.length>0 ?`/reservation/tarifs/${JSON.stringify({_id:{$in: tfId}})}`:`/reservation/tarifs`), {method:'GET', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }}).then(async response=>{
                if (response.status === 200) {
                    const json = await response.json()
                    setPrice(passagers.filter(psg=>psg.categorie!=='nourrisson')
                    .reduce((tt, current)=>tt+(json.find(tf=>tf.isA_R===(isShopping?isShopping:isAR)&&current.categorie?.toLowerCase().includes(tf.categorie))?.prix??0),
                    0))
                }
            })
        }

        if (rotations.length<1||passagers.length<1) {
            navigate('/')
        }else if (passagers.length<10) {
            getTarifs()
        }
    }, [isAR, isShopping, passagers, rotations])

    return(isMobile?
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            flex:1,
            paddingX: '10px',
            alignItems:'center', 
        }}>
            <Typography variant="h3" align="center" marginBlock={2}>
                Récapitulatif
            </Typography>

            <Stack direction={'column'} spacing={2} alignItems='center' marginX={2}>
                {rotations.map((rtn, index) => (
                    <Box key={index} display='flex' flex={1} flexDirection='column' sx={{
                        backgroundColor: 'primary.light', padding: 2 , marginBottom: '15px' 
                    }}>
                        <Typography variant="subtitle1" align="center">
                            {index < 1 ? 'ALLER' : 'RETOUR'}
                        </Typography>
                        <Typography align="center">{rtn.from?.nom?.toUpperCase() + ' -> ' + rtn.to?.nom?.toUpperCase()}</Typography>
                        <Typography align="center" fontWeight='bold'>
                            {moment.utc(rtn?.date_depart ?? '').format('dddd DD MMMM yyyy')}
                        </Typography>
                        
                        <Box display='flex' justifyContent='space-between' alignItems='center'>
                            <Box>
                                <Typography>Date de départ:</Typography>
                                <Typography>Fin d'embarquement:</Typography>
                                <Typography>Heure d'arrivée prévue:</Typography>
                            </Box>
                            <Box>
                                <Typography align="right">{moment.utc(rtn?.date_depart ?? '').format('DD/MM/yyyy')}</Typography>
                                <Typography align="right">
                                    {moment(rtn.heure_depart.join(':'), 'HH:mm').add(-45, 'minute').format('H:mm').replace(':', 'h')}
                                </Typography>
                                <Typography align="right">
                                    {moment(rtn.heure_depart.join(':'), 'HH:mm').add(rtn.duree, 'minutes').format('H:mm').replace(':', 'h')}
                                </Typography>
                            </Box>
                        </Box>
                        <Typography>Passagers:</Typography>
                        {passagers.map(psg => (
                            <Typography key={psg._id} textTransform='capitalize' marginLeft={1}>
                                - {psg.nom + ' ' + psg.prenom}
                            </Typography>
                        ))}
                    </Box>
                ))}
            </Stack>

            <Typography variant="h4" fontWeight={600} color='rose.main' align="center" marginTop={2}>
                TOTAL
            </Typography>

            <Box display='flex' justifyContent='space-between' marginInline={2} marginBottom={4}>
                <Typography>
                    {isAR ? 'Aller/Retour' : 'Aller simple'} <span style={{ fontWeight: 'bold' }}>x{passagers.length}</span>
                </Typography>
                {passagers.length < 10 ?
                    <Box width={'100%'} minWidth={'100%'}>
                        <Typography align="right">{price.toFixed(2)}€</Typography>
                        <Divider style={{
                            marginBlock: 5,
                            borderBottomWidth: '3px',
                            opacity: 0.4,
                            borderColor: theme.palette.primary.light
                        }} />
                        <Typography align="right">TOTAL TTC<br />bagages inclus</Typography>
                        <Typography variant="h4" align="right" color='rose.main' marginTop={2}>
                            {price.toFixed(2)}€
                        </Typography>
                    </Box>
                    : <Typography align="center">
                        Vous êtes éligible à un tarif de groupe<br />
                        <span style={{ fontWeight: 'bold', color: 'red' }}>Veuillez contacter l'Agence<br />0696 43 76 68</span>
                    </Typography>}
            </Box>

            {passagers.length < 10 && price > 0 ?
                <Button variant="contained" sx={{ marginBottom: 2 }} disabled={rotations.length < (isAR ? 2 : 1) || passagers.length < 1} onClick={() => navigate('/paiement')}>
                    Continuer
                </Button>
                :
                <Button variant="contained" sx={{ marginBottom: 2 }} disabled={rotations.length < (isAR ? 2 : 1)} onClick={handleSubmit}>
                    Valider et enregistrer
                </Button>
            }

            <Backdrop open={load}><CircularProgress /></Backdrop>
        </Box>
        :<Paper elevation={6} sx={{display:'flex', flexDirection:'column', flex:1, marginX:'30px', marginTop:'40px'}}>
            <Typography variant="h2" align="center" marginTop='20px' marginBottom={7}>Récapitulatif</Typography>
            <Stack direction='row' spacing={10} alignItems='center' marginX={10}>
                {rotations.map((rtn, index)=>
                    <Box key={index} display='flex' flex={1} flexDirection='column' sx={{backgroundColor: 'primary.light', padding:'15px'}}>
                        <Typography variant="subtitle1" align="center">{index<1? 'ALLER' : 'RETOUR'}</Typography>
                        <Typography align="center">{rtn.from?.nom?.toUpperCase()+' -> '+rtn.to?.nom?.toUpperCase()}</Typography>
                        <Typography align="center" fontWeight='bold'>{moment.utc(rtn?.date_depart??'').format('dddd DD MMMM yyyy')}</Typography>
                        <Box display='flex' justifyContent='space-between' alignItems='center'>
                            <Typography>Date de départ:</Typography>
                            <Typography align="right">{moment.utc(rtn?.date_depart??'').format('DD/MM/yyyy')}</Typography>
                        </Box>
                        <Box display='flex' justifyContent='space-between' alignItems='center'>
                            <Typography>Fin d'embarquement:</Typography>
                            <Typography align="right">{moment(rtn.heure_depart.join(':'), 'HH:mm').add(-45, 'minute').format('H:mm').replace(':', 'h')}</Typography>
                        </Box>
                        <Box display='flex' justifyContent='space-between' alignItems='center'>
                            <Typography>Heure d'arrivée prévue:</Typography>
                            <Typography align="right">{moment(rtn.heure_depart.join(':'), 'HH:mm').add(rtn.duree, 'minutes').format('H:mm').replace(':', 'h')}</Typography>
                        </Box>
                        <Typography>Passagers:</Typography>
                        {passagers.map(psg=><Typography key={psg._id} textTransform='capitalize' marginLeft={1}>- {psg.nom+' '+psg.prenom}</Typography>)}
                    </Box>
                )}
            </Stack>
            <Divider style={{marginInline: '3%', marginBlock:'30px',minWidth:'350', borderBottomWidth:'5px', opacity:0.4, borderColor:theme.palette.primary.light}}/>
            <Typography variant="h2" fontWeight={600} color='rose.main' align="center">TOTAL</Typography>
            <Box display='flex' justifyContent='space-between' marginInline={15} marginBottom={4}>
                <Typography>{isAR? 'Aller/Retour' : 'Aller simple'} <span style={{fontWeight:'bold'}}>x{passagers.length}</span></Typography>
                {passagers.length<10 ?<Box width='15%' minWidth='150px'>
                    <Typography align="right">{price.toFixed(2)}€</Typography>
                    <Divider style={{marginBlock:5, borderBottomWidth:'3px', opacity:0.4, borderColor:theme.palette.primary.light}}/>
                    <Typography align="right">TOTAL TTC<br/>bagages inclus</Typography>
                    <Typography variant="h3" align="right" color='rose.main' marginTop={2}>{price.toFixed(2)}€</Typography>
                </Box> :
                <Typography align="center">Vous êtes éligible à un tarif de groupe<br/><span style={{fontWeight:'bold', color:'red'}}>Veuillez contacter l'Agence<br/>0696 43 76 68</span></Typography>}
            </Box>
            {passagers.length<10&&price>0 ?
            <Button variant="contained" sx={{marginBottom:5}} disabled={rotations.length<(isAR?2:1)||passagers.length<1} style={{alignSelf:'end', marginRight:'50px'}} onClick={()=>navigate('/paiement')}>continuer</Button>
            : <Button variant="contained" sx={{marginBottom:5}} disabled={rotations.length<(isAR?2:1)} style={{alignSelf:'end', marginRight:'50px'}} onClick={handleSubmit}>valider et enregistrer</Button>}
            
            <Backdrop open={load}><CircularProgress/></Backdrop>
        </Paper>
    )
}